<template>
  <div class="th-patient-header--wrp">
    <div class="th-patient-header--wrp---child">
      <div class="th-patient-header--wrp---child--page">
        <h2>
          {{ $t('Patients', currentSelectedLanguage) }}
          ({{ patientsCount }})
        </h2>
      </div>
      <div class="th-patient-header--wrp---child--button">
        <narrow-button
          @narrowButtonEventEmit="callBtn"
          :buttonTextNarrow="$t('show only flagged data', currentSelectedLanguage)"
          :classNrwBtnArray="classNrwBtnArrayInhProps"
        />
      </div>
      <div class="th-patient-header--wrp---child--drinfo">
        <p class="text-right" v-if="userProfile&&userProfile.Name">
          {{ userProfile.Name }}
        </p>
        <p class="text-right" v-if="userProfile&&userProfile.ClinicName">
          {{ userProfile.ClinicName }}
        </p>
      </div>
    </div>
    <div class="filters">
      <dropdown-input
        v-if="userProfile.userRole === 1"
        :selectedArray="allDoctorsArray"
        :labelTextDisplayProp="inheritFromLabel"
        :placeHolderList="$t('Select user', currentSelectedLanguage)"
        @inputSelected="selectedUser"
        :drop-down-input-no-data="$t('Data not found', currentSelectedLanguage)"
      />
      <simple-input
        :placeholderTextSimpleText="$t('Search patient', currentSelectedLanguage)"
        @keyUpSimpleInputEvent="simpleInputKeyDown"
      />
      <input-calendar
        :calendarInputPlaceholder="$t('Search date', currentSelectedLanguage)"
        @keyUpInputEventCalendar="setCalendar"
        :showClearBtn="!!(this.getCurrentFilterData.FirstDate || this.getCurrentFilterData.LastDate)"
      />
      <dropdown
        labelTextDisplayProp="label"
        :placeHolderList="$t('Age', currentSelectedLanguage)"
        :drop-down-input-no-data="$t('NoAgeOfPatient', currentSelectedLanguage)"
        :searc-ability-props="false"
        :selectedArray="ageArray"
        @inputSelected="setAge"
        class="special-input"
        :resetValueIfSelectAll="true"
      />
      <dropdown
        labelTextDisplayProp="label"
        :placeHolderList="$t('BI-RADS', currentSelectedLanguage)"
        :drop-down-input-no-data="$t('NoBiRadsData', currentSelectedLanguage)"
        :searc-ability-props="false"
        :selectedArray="biRadsArray"
        @inputSelected="setBiRads"
        class="special-input"
        :resetValueIfSelectAll="true"
      />
      <dropdown
        labelTextDisplayProp="label"
        :placeHolderList="$t('PalpableMass', currentSelectedLanguage)"
        :searc-ability-props="false"
        :selectedArray="palpableMassInTheBreastArray"
        @inputSelected="setPalpableMass"
        class="special-input"
        :resetValueIfSelectAll="true"
      />
      <dropdown
        v-if="userProfile.userRole !== 1"
        labelTextDisplayProp="label"
        :placeHolderList="$t('FamilyHistory', currentSelectedLanguage)"
        :searc-ability-props="false"
        :selectedArray="familyHistoryArray"
        @inputSelected="setFamilyHistory"
        class="special-input"
        :resetValueIfSelectAll="true"
      />
    </div>
    <div :class="`${advancedFiltersOpened?'open':''} advanced-filters-section filters`">
      <dropdown
        v-if="userProfile.userRole === 1"
        labelTextDisplayProp="label"
        :placeHolderList="$t('FamilyHistory', currentSelectedLanguage)"
        :searc-ability-props="false"
        :selectedArray="familyHistoryArray"
        @inputSelected="setFamilyHistory"
        class="special-input"
        :resetValueIfSelectAll="true"
      />
      <dropdown
        labelTextDisplayProp="label"
        :placeHolderList="$t('PainInTheBreast', currentSelectedLanguage)"
        :searc-ability-props="false"
        :selectedArray="painInTheBreastArray"
        @inputSelected="setPainInTheBreast"
        class="special-input"
        :resetValueIfSelectAll="true"
      />
      <dropdown
        labelTextDisplayProp="label"
        :placeHolderList="$t('BiopsyResult', currentSelectedLanguage)"
        :searc-ability-props="false"
        :selectedArray="biopsyResultArray"
        @inputSelected="setBiopsyResult"
        class="special-input"
        :resetValueIfSelectAll="true"
      />
      <dropdown
        labelTextDisplayProp="label"
        :placeHolderList="$t('TumorSide', currentSelectedLanguage)"
        :searc-ability-props="false"
        :selectedArray="tumorSideArray"
        @inputSelected="setTumorSide"
        class="special-input"
        :resetValueIfSelectAll="true"
      />
      <dropdown
        labelTextDisplayProp="label"
        :placeHolderList="$t('TumorSize', currentSelectedLanguage)"
        :searc-ability-props="false"
        :selectedArray="tumorSizeArray"
        @inputSelected="setTumorSize"
        class="special-input"
        :resetValueIfSelectAll="true"
      />
      <dropdown
        labelTextDisplayProp="label"
        :placeHolderList="$t('TumorLocation', currentSelectedLanguage)"
        :searc-ability-props="false"
        :selectedArray="tumorLocationArray"
        @inputSelected="setTumorLocation"
        class="special-input"
        :resetValueIfSelectAll="true"
      />
      <dropdown
        labelTextDisplayProp="label"
        :placeHolderList="$t('MenstrualCycle', currentSelectedLanguage)"
        :searc-ability-props="false"
        :selectedArray="menstrualCycleArray"
        @inputSelected="setMenstrualCycle"
        class="special-input"
        :resetValueIfSelectAll="true"
      />
    </div>
    <div class="advanced-filters">
      <hr/>
      <div class="advanced-filters-text" @click="openCloseAdvancedFilters">
        <span>Advanced filters </span>
        <b-iconstack :rotate="advancedFiltersOpened ? '-90%' : '90%'">
          <b-icon stacked icon="chevron-right" shift-h="3"></b-icon>
          <b-icon stacked icon="chevron-right" shift-h="0"></b-icon>
        </b-iconstack>
      </div>
      <hr/>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import InputCalendar from '../../../../atoms/inputCalendar.vue';
import DropdownInput from '../../../../atoms/dropdownInput.vue';
import NarrowButton from '../../../../atoms/narrowButton.vue';
import SimpleInput from '../../../../atoms/simpleInput.vue';
import dropdown from '../../../../atoms/dropdownSimple.vue';
import {
  BiopsyResultEnum,
  PainInTheBreastEnum,
  PalpableMassInTheBreastEnum,
  PatientAgeEnum,
  TumorLocationEnum,
  TumorSideEnum,
  TumorSizeEnum,
  BiRadsEnum,
  MenstrualCycleEnum, FamilyHistoryOfBreastCancerEnum
} from "../../../../../core/helpers/variables/constants";
import {
  PURGE_PATIENT_ARRAY,
  SELECTED_DOCTOR_ARRAY
} from "../../../../../store/modules/patients.module";
import moment from "moment";
import localVariable from "../../../../../core/helpers/variables/localVariables";
import {ELEMENT_COND_DURING_LOAD} from "../../../../../store";
import {getProfile} from "../../../../../core/services/jwt";

export default {
  name: 'patientListHeader',
  components: {
    SimpleInput,
    NarrowButton,
    InputCalendar,
    DropdownInput,
    dropdown,
  },
  props: {
    inheritShowClearBtn: {
      type: Boolean,
      default: false,
    },
    inheritFromLabel: {
      type: String,
      default: 'label',
    },
    inheitButtonText: {
      type: String,
      default: 'nono',
    },
    patientsCount: {
      type: Number,
      default: 0,
    },
    patientUserName: {
      type: String,
      default: 'Dr. Unknown',
    },
    patientClinicName: {
      type: String,
      default: 'Default clicnic name',
    },
    classNrwBtnArrayInhProps: {
      type: Array,
      default() {
        return ['th-narrow-button'];
      },
    },
  },
  computed: {
    ...mapState(
      {
        currentSelectedLanguage: (state) => state.currentSelectedLanguage,
      },
    ),
    ...mapGetters(
      {
        allDoctorsArray: 'getDoctorsArray',
        userProfile: 'getProfile',
        pageNumber: 'getNumberPagination',
        getCurrentFilterData: 'getCurrentFilter',
        selectedDoctorArray: 'getSelectedDoctorsArray',
      },
    ),
  },
  mounted() {
    this.loadData();
  },
  methods: {
    openCloseAdvancedFilters() {
      this.advancedFiltersOpened = !this.advancedFiltersOpened;
    },
    getArrayFromEnum(prop) {
      return Object.values(prop).map(item => {
        return {...item, label: this.$t(item.label, this.currentSelectedLanguage)}
      });
    },
    getBiRadsArray() {
      let biRads = {
        ...BiRadsEnum,
        all: {
          ...BiRadsEnum.all,
          label: this.$t(BiRadsEnum.all.label, this.currentSelectedLanguage)
        },
        notSelected: {
          ...BiRadsEnum.notSelected,
          label: this.$t(BiRadsEnum.notSelected.label, this.currentSelectedLanguage),
        }
      }
      return Object.values(biRads).map(item => {
        return {...item}
      });
    },
    async callBtn() {
      const data = {
        ...this.getCurrentFilterData,
        PageNumber: 0,
        ShowOnlyFaggedData: !this.getCurrentFilterData.ShowOnlyFaggedData//?
      };
      await this.getAllPatients(data);
    },
    async simpleInputKeyDown(value) {
      await this.getAllPatients({
        ...this.getCurrentFilterData,
        ClinicAccountId: value,
        PageNumber: 0
      });
    },
    async setCalendar(value) {
      const start = value.FirstDate ? moment(value.FirstDate).format(localVariable.MOMENTJS.YYYY_MM_DD) : null;
      const last = value.LastDate ? moment(value.LastDate).format(localVariable.MOMENTJS.YYYY_MM_DD) : null;
      await this.getAllPatients({...this.getCurrentFilterData, FirstDate: start, LastDate: last});
    },
    async selectedUser(value) {
      if (value) {
        const currentUserIds = value.map((item) => item.Id);
        await this.$store.dispatch(SELECTED_DOCTOR_ARRAY, value);
        await this.getAllPatients({...this.getCurrentFilterData, UsersIds: currentUserIds});
      }
    },
    async setAge(selectedAge) {
      await this.getAllPatients({...this.getCurrentFilterData, Age: selectedAge.value});
    },
    async setBiRads(selectedBiRads) {
      await this.getAllPatients({...this.getCurrentFilterData, BiRads: selectedBiRads.value});
    },
    async setPalpableMass(selectedPalpableMassInTheBreast) {
      await this.getAllPatients({
        ...this.getCurrentFilterData,
        PalpableMassInTheBreast: selectedPalpableMassInTheBreast.value
      });
    },
    async setFamilyHistory(selectedFamilyHistory) {
      await this.getAllPatients({
        ...this.getCurrentFilterData,
        FamilyHistory: selectedFamilyHistory.value
      });
    },
    async setPainInTheBreast(selectedPainInTheBreast) {
      await this.getAllPatients({
        ...this.getCurrentFilterData,
        PainInTheBreast: selectedPainInTheBreast.value
      });
    },
    async setBiopsyResult(selectedBiopsyResult) {
      await this.getAllPatients({
        ...this.getCurrentFilterData,
        BiopsyResult: selectedBiopsyResult.value
      });
    },
    async setTumorSize(selectedTumorSize) {
      await this.getAllPatients({...this.getCurrentFilterData, TumorSize: selectedTumorSize.value});
    },
    async setTumorSide(selectedTumorSide) {
      await this.getAllPatients({...this.getCurrentFilterData, TumorSide: selectedTumorSide.value});
    },
    async setTumorLocation(selectedTumorLocation) {
      await this.getAllPatients({
        ...this.getCurrentFilterData,
        TumorLocation: selectedTumorLocation.value
      });
    },
    async setMenstrualCycle(selectedMenstrualCycle) {
      await this.getAllPatients({
        ...this.getCurrentFilterData,
        MenstrualCycle: selectedMenstrualCycle.value
      });
    },
    async loadData() {
      await this.$store.dispatch(ELEMENT_COND_DURING_LOAD, localVariable.LOADER.LOADER_SET_VALUE);
      const userStorage = getProfile();
      const userRole = typeof userStorage !== 'undefined' && userStorage.role !== 'undefined' && userStorage.role === 1 ? 1 : null;
      await this.getAllPatients({
        ShowOnlyFaggedData: false,
        PageNumber: 0,
        Status: null,
        FirstDate: null,
        LastDate: null,
        UsersIds: [],
        ClinicAccountId: null,
        sortBy: null,
        direction: null,
        Age: null,
        BiRads: null,
        PalpableMassInTheBreast: null,
        FamilyHistory: null,
        PainInTheBreast: null,
        BiopsyResult: null,
        TumorSide: null,
        TumorSize: null,
        TumorLocation: null
      })
      await this.$store.dispatch(ELEMENT_COND_DURING_LOAD, localVariable.LOADER.LOADER_REMOVE_VALUE);
      if (userRole === 1) {
        await this.getAllUsers()
      }
    },
    async initData() {
      await this.$store.dispatch(PURGE_PATIENT_ARRAY, this.saveStatusFilter);
    },
    ...mapActions({
      getAllPatients: 'getAllPatients',
      getAllUsers: 'getAllUsers'
    })
  },
  data() {
    return {
      ageArray: this.getArrayFromEnum(PatientAgeEnum),
      palpableMassInTheBreastArray: this.getArrayFromEnum(PalpableMassInTheBreastEnum),
      painInTheBreastArray: this.getArrayFromEnum(PainInTheBreastEnum),
      biopsyResultArray: this.getArrayFromEnum(BiopsyResultEnum),
      tumorSideArray: this.getArrayFromEnum(TumorSideEnum),
      tumorSizeArray: this.getArrayFromEnum(TumorSizeEnum),
      tumorLocationArray: this.getArrayFromEnum(TumorLocationEnum),
      biRadsArray: this.getBiRadsArray(),
      menstrualCycleArray: this.getArrayFromEnum(MenstrualCycleEnum),
      familyHistoryArray: this.getArrayFromEnum(FamilyHistoryOfBreastCancerEnum),
      advancedFiltersOpened: false
    };
  },
}
</script>

<style lang="scss" scoped>
.special-input {
  .vs__actions {
    display: flex;
    top: 0;
  }
}

.th-patient-header--wrp {
  display: flex;
  flex-direction: column;
  @include space($type: margin, $direction: bottom, $amount: 24px);

  &---child {
    display: flex;
    flex-direction: row;

    &:last-of-type {
      width: 68%;
    }

    &:first-child {
      @include space($type: margin, $direction: bottom, $amount: 19px);
    }

    &--page {
      & > h2 {
        @include font-source($openSans, 18px, $secondary, 600, normal, normal);
        @include space($type: margin, $direction: bottom, $amount: 0);
        text-transform: capitalize;
      }
    }

    &--button {
      & > button {
        text-transform: uppercase;
        @include space($type: margin, $direction: left, $amount: 28px);
      }
    }

    &--drinfo {
      @include space($type: margin, $direction: left, $amount: auto);

      & > p {
        margin-bottom: 0;
        text-transform: capitalize;

        &:first-child {
          @include font-source($openSans, 12px, $secondary, 600, normal, normal);
        }

        &:last-child {
          @include font-source($openSans, 11px, rgba(30, 45, 62, 0.6), 600, normal, normal);
        }
      }
    }
  }

  .filters {
    display: flex;
    flex-wrap: wrap;
    row-gap: 15px;
    justify-content: space-between;

    & > * {
      width: 32%;
    }
  }

  .advanced-filters-section {
    //max-height: 0;
    //transition: max-height 400ms;
    //visibility: hidden;
    display: none;

    &.open {
      //max-height: 1000px;
      //visibility: visible;
      display: flex;
      margin-top: 15px;
    }
  }

  .advanced-filters {
    display: flex;
    margin-top: 25px;

    .advanced-filters-text {
      cursor: pointer;
      margin-right: 15px;
      margin-left: 9px;
    }

    span {
      white-space: nowrap;
    }

    hr {
      @include border(1px, solid, rgba(30, 45, 62, 0.1), all);
      margin: auto 0 auto 0;
      white-space: nowrap;
      width: 100%;
    }
  }

  .double-down-arrow {
    rotation: 90deg;
    margin: auto;
  }
}
</style>
