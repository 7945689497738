<template>
  <div class="th-patient֊wrp">
    <patient-list-header
      ref="b"
      :patientsCount="itemsCount"
      inheritFromLabel="Name"
      :classNrwBtnArrayInhProps="
      getCurrentFilterData.ShowOnlyFaggedData
      ? ['th-narrow-button-select']
      :
      ['th-narrow-button']"
    />

    <patient-table
      :status-condition="this.getCurrentFilterData.Status||''"
      :itemsPaginationLength="itemsCount"
      :element-cond-prop="elementCondition"
      :itemsPropsArray="getAllPatientArray"
      @onSelectedRowEmit="selectedPatient"
      @filterEmit="filterStatus"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import PatientListHeader
  from '../../../../../components/moleculs/dashboard/patient/pageHead/patientListHeader.vue';
import PatientTable
  from '../../../../../components/moleculs/dashboard/patient/pageBody/patientTable.vue';

export default {
  name: 'patientPage',
  components: {
    PatientListHeader,
    PatientTable,
  },
  data() {
    return {
      // saveStatusFilter: false,
    };
  },
  computed: {
    itemsCount() {
      // return this.getTableData.paginationTotal * 50;
      return this.getTableData.patientsCount;
    },
    ...mapState(
      {
        currentSelectedLanguage: (state) => state.currentSelectedLanguage,
      },
    ),
    ...mapGetters({
      getAllPatientArray: 'getPatientsArray',
      getCurrentFilterData: 'getCurrentFilter',
      getTableData: 'getPatientTableStat',
      elementCondition: 'elementCond',
      page: 'getNumberPagination',
    }),
  },

  methods: {
    async filterStatus(val) {
      await this.getAllPatients({
        ClinicAccountId:
          this.getCurrentFilterData.ClinicAccountId
            ? this.getCurrentFilterData.ClinicAccountId : null,
        Status: val,
        FirstDate:
          this.getCurrentFilterData.FirstDate ? this.getCurrentFilterData.FirstDate : null,
        LastDate:
          this.getCurrentFilterData.LastDate ? this.getCurrentFilterData.LastDate : null,
        PageNumber:
          this.getCurrentFilterData.PageNumber ? this.getCurrentFilterData.PageNumber : 0,
        UsersIds:
          this.getCurrentFilterData.UsersIds.length > 0 ? this.getCurrentFilterData.UsersIds : [],
        ShowOnlyFaggedData:
          this.getCurrentFilterData.ShowOnlyFaggedData
            ? this.getCurrentFilterData.ShowOnlyFaggedData : false,
        sortBy:
          this.getCurrentFilterData.sortBy
            ? this.getCurrentFilterData.sortBy : null,
        direction:
          this.getCurrentFilterData.direction
            ? this.getCurrentFilterData.direction : null,
        Age: this.getCurrentFilterData.Age
          ? this.getCurrentFilterData.Age : null,
        BiRads: this.getCurrentFilterData.BiRads
          ? this.getCurrentFilterData.BiRads : null,
      });
    },
    selectedPatient(value) {
      if (!value || value.length === 0) {
        return;
      }
      const patientId = value.length > 0 ? value[0].Id : null;
      // this.saveStatusFilter = true;
      const routeData = this.$router.resolve({
        name: 'doctorPatientpageDetail',
        params: {patientId: patientId, allowOnlyWithPush: true},
      });
      window.open(routeData.href, '_blank');
    },
    ...mapActions({
      getAllPatients: 'getAllPatients'
    })
  },
  destroyed() {
    //this.initData();
  },
};
</script>

<style lang="scss" scoped>
.th-patient֊wrp {
  @include space($type: padding, $direction: top, $amount: 36px);
  @include space($type: padding, $direction: right, $amount: 32px);
  @include space($type: padding, $direction: bottom, $amount: 34px);
  @include space($type: padding, $direction: left, $amount: 30px);
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: $jessy-jay;
  overflow-y: auto;
}
</style>
